import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PacmanLoader from "react-spinners/PacmanLoader";
const Home = lazy(() => import("../pages/Home"));
const WhyChooseUs = lazy(() => import("../pages/why-choose-us"));
const ContactUs = lazy(() => import("../pages/contact_us"));
const NewNavbar = lazy(() => import("../components/new-navbar/NewNavbar"));
const Leadership = lazy(() => import("../pages/leadership"));
// const Navbar = lazy(() => import('../components/navbar/Navbar'))
const Footer = lazy(() => import("../components/footer/Footer"));
const About = lazy(() => import("../pages/About"));
const Careers = lazy(() => import("../pages/careers"));
const Blog = lazy(() => import("../pages/Blog"));
const BlogDetails = lazy(() => import("../pages/BlogDetails"));
const Industries = lazy(() => import("../pages/Industries"));
const CaseStudies = lazy(() => import("../pages/CaseStudies"));
const CaseStudiesDetails = lazy(() => import("../pages/CaseStudiesDetails"));
const IndustriesInfoPage = lazy(() => import("../pages/Industries-info"));
const TechnologiesPage = lazy(() => import("../pages/technologies"));
const OurMethodology = lazy(() => import("../pages/our-methodology"));
const TermsConditions = lazy(() => import("../pages/TermsConditions"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));
const Portfolio = lazy(() => import("../pages/Portfolio"));
// const ServicesPage = lazy(() => import ('../pages/services'))
const DigitalMarketingServicePage = lazy(() =>
  import("../pages/service/digital-marketing")
);
const SeoPage = lazy(() =>
  import("../pages/service/digital-marketing-pages/seo-page")
);
const SemPage = lazy(() =>
  import("../pages/service/digital-marketing-pages/sem-page")
);
const PpcPage = lazy(() =>
  import("../pages/service/digital-marketing-pages/ppc-page")
);
const ContentMarketing = lazy(() =>
  import("../pages/service/digital-marketing-pages/content-marketing")
);
const SocialMedialMarketing = lazy(() =>
  import("../pages/service/digital-marketing-pages/social-media-marketing")
);
const EmailMarketing = lazy(() =>
  import("../pages/service/digital-marketing-pages/email-marketing")
);
const StaffAugmentation = lazy(() =>
  import("../pages/service/staff-augmentation")
);
const HireATeam = lazy(() =>
  import("../pages/service/staff-augmentation-pages/hire-a-team")
);
const HireADeveloper = lazy(() =>
  import("../pages/service/staff-augmentation-pages/hire-a-developer")
);
const BuildYourOwnTeam = lazy(() =>
  import("../pages/service/staff-augmentation-pages/build-your-own-team")
);
const UiUxService = lazy(() => import("../pages/service/ui-ux"));
const DesignConsultation = lazy(() =>
  import("../pages/service/design-pages/design-consultation")
);
const UiUxDesign = lazy(() =>
  import("../pages/service/design-pages/ui-ux-design")
);
const UserResearchAndFeedback = lazy(() =>
  import("../pages/service/design-pages/user-research-and-feedback")
);
const WireFramingAndMockups = lazy(() =>
  import("../pages/service/design-pages/wire-framing-and-mockups")
);
const MobileDevelopment = lazy(() =>
  import("../pages/service/mobile-development")
);
const AndroidDevelopment = lazy(() =>
  import("../pages/service/mobile-development-pages/android-development")
);
const IosDevelopment = lazy(() =>
  import("../pages/service/mobile-development-pages/ios-development")
);
const CrossPlatformDevelopment = lazy(() =>
  import("../pages/service/mobile-development-pages/cross-platform-development")
);
const ReactNativeDevelopment = lazy(() =>
  import("../pages/service/mobile-development-pages/react-native-development")
);
const WebDevelopment = lazy(() => import("../pages/service/web-development"));
const CustomDevelopment = lazy(() =>
  import("../pages/service/web-development-pages/custom-development")
);
const BackEndDevelopment = lazy(() =>
  import("../pages/service/web-development-pages/backend-development")
);
const FrontEndDevelopment = lazy(() =>
  import("../pages/service/web-development-pages/frontend-development")
);
const RubyOnRailsDevelopment = lazy(() =>
  import("../pages/service/web-development-pages/ruby-on-rails-development")
);
const PythonDjangoDevelopment = lazy(() =>
  import("../pages/service/web-development-pages/python-django-development")
);
const ReactDevelopment = lazy(() =>
  import("../pages/service/web-development-pages/react-development")
);
const AngularDevelopment = lazy(() =>
  import("../pages/service/web-development-pages/angular-development")
);
const StrategyAndArchitectural = lazy(() =>
  import("../pages/service/strategy-and-architectural")
);
const DigitalStrategyAndConsulation = lazy(() =>
  import(
    "../pages/service/strategy-and-architectural-pages/digital-strategy-and-consultation"
  )
);
const ProductResearchAndPlanning = lazy(() =>
  import(
    "../pages/service/strategy-and-architectural-pages/product-research-and-planning"
  )
);
const RubyOnRailsConsulting = lazy(() =>
  import(
    "../pages/service/strategy-and-architectural-pages/ruby-on-rails-consulting"
  )
);
const ApplicationArchitecting = lazy(() =>
  import(
    "../pages/service/strategy-and-architectural-pages/application-architecting"
  )
);
const SchemaDevelopment = lazy(() =>
  import("../pages/service/strategy-and-architectural-pages/schema-development")
);
const ApiDevelopment = lazy(() =>
  import("../pages/service/strategy-and-architectural-pages/api-development")
);
const DataServices = lazy(() => import("../pages/service/data-services"));
const DataResearch = lazy(() =>
  import("../pages/service/data-services-pages/data-research")
);
const DataExtraction = lazy(() =>
  import("../pages/service/data-services-pages/data-extraction")
);
const DataCollection = lazy(() =>
  import("../pages/service/data-services-pages/data-collection")
);
const DataMigration = lazy(() =>
  import("../pages/service/data-services-pages/data-migration")
);
const DataNormalisation = lazy(() =>
  import("../pages/service/data-services-pages/data-normalisation")
);
const DataAnalysis = lazy(() =>
  import("../pages/service/data-services-pages/data-analysis")
);
const CloudDevOps = lazy(() => import("../pages/service/cloud-devops"));
const Deployment = lazy(() =>
  import("../pages/service/cloud-devops-pages/deployment")
);
const Containerisation = lazy(() =>
  import("../pages/service/cloud-devops-pages/containerisation")
);
const Scaling = lazy(() =>
  import("../pages/service/cloud-devops-pages/scaling")
);
const SecurityAndMonitoring = lazy(() =>
  import("../pages/service/cloud-devops-pages/security-and-monitoring")
);
const NotFoundPage = lazy(() => import("../pages/notfound/NotFoundPage"));
// const SitemapPage = lazy(() => import('../pages/sitemap/SitemapPage'))

const routesView = () => {
  const routes = [
    {
      path: "/",
      exact: true,
      navbar: (props) => <NewNavbar active="Home" {...props} />,
      footer: () => <Footer />,
      main: (props) => <Home {...props} />,
    },
    {
      path: "/about",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <About {...props} />,
    },
    {
      path: "/why-choose-us",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <WhyChooseUs {...props} />,
    },
    {
      path: "/contact-us",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <ContactUs {...props} />,
    },
    {
      path: "/blog",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <Blog {...props} />,
    },
    {
      path: "/blog/:id",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <BlogDetails {...props} />,
    },
    {
      path: "/portfolio",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <Portfolio {...props} />,
    },
    {
      path: "/industries",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <Industries {...props} />,
    },
    {
      path: "/industries/:industriesId",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <IndustriesInfoPage {...props} />,
    },
    {
      path: "/leadership",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <Leadership {...props} />,
    },
    {
      path: "/careers",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <Careers {...props} />,
    },
    {
      path: "/case-studies",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <CaseStudies {...props} />,
    },
    {
      path: "/case-studies/:id",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <CaseStudiesDetails {...props} />,
    },
    {
      path: "/services/digital-marketing",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <DigitalMarketingServicePage {...props} />,
    },
    {
      path: "/services/seo",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <SeoPage {...props} />,
    },
    {
      path: "/services/sem",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <SemPage {...props} />,
    },
    {
      path: "/services/ppc",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <PpcPage {...props} />,
    },
    {
      path: "/services/content-marketing",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <ContentMarketing {...props} />,
    },
    {
      path: "/services/email-marketing",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <EmailMarketing {...props} />,
    },
    {
      path: "/services/social-media-marketing",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <SocialMedialMarketing {...props} />,
    },
    {
      path: "/services/staff-augmentation",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <StaffAugmentation {...props} />,
    },
    {
      path: "/services/hire-a-team",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <HireATeam {...props} />,
    },
    {
      path: "/services/hire-a-developer",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <HireADeveloper {...props} />,
    },
    {
      path: "/services/build-your-own-team",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <BuildYourOwnTeam {...props} />,
    },
    {
      path: "/services/web-development",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <WebDevelopment {...props} />,
    },
    {
      path: "/services/custom-development",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <CustomDevelopment {...props} />,
    },
    {
      path: "/services/backend-development",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <BackEndDevelopment {...props} />,
    },
    {
      path: "/services/frontend-development",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <FrontEndDevelopment {...props} />,
    },
    {
      path: "/services/ruby-on-rails-development",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <RubyOnRailsDevelopment {...props} />,
    },
    {
      path: "/services/python-django-development",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <PythonDjangoDevelopment {...props} />,
    },
    {
      path: "/services/react-development",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <ReactDevelopment {...props} />,
    },
    {
      path: "/services/angular-development",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <AngularDevelopment {...props} />,
    },
    {
      path: "/services/cloud-devOps",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <CloudDevOps {...props} />,
    },
    {
      path: "/services/deployment-and-maintenance",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <Deployment {...props} />,
    },
    {
      path: "/services/containerisation",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <Containerisation {...props} />,
    },
    {
      path: "/services/scaling-and-optimisation",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <Scaling {...props} />,
    },
    {
      path: "/services/security-and-monitoring",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <SecurityAndMonitoring {...props} />,
    },
    {
      path: "/services/data-services",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <DataServices {...props} />,
    },
    {
      path: "/services/data-research-and-analytics",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <DataResearch {...props} />,
    },
    {
      path: "/services/data-extraction-and-scraping",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <DataExtraction {...props} />,
    },
    {
      path: "/services/data-collection",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <DataCollection {...props} />,
    },
    {
      path: "/services/data-migration",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <DataMigration {...props} />,
    },
    {
      path: "/services/data-normalisation",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <DataNormalisation {...props} />,
    },
    {
      path: "/services/data-analysis",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <DataAnalysis {...props} />,
    },
    {
      path: "/services/strategy-and-architectural",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <StrategyAndArchitectural {...props} />,
    },
    {
      path: "/services/digital-strategy-and-consultation",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <DigitalStrategyAndConsulation {...props} />,
    },
    {
      path: "/services/product-research-and-planning",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <ProductResearchAndPlanning {...props} />,
    },
    {
      path: "/services/ruby-on-rails-consulting",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <RubyOnRailsConsulting {...props} />,
    },
    {
      path: "/services/application-architecting",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <ApplicationArchitecting {...props} />,
    },
    {
      path: "/services/schema-development",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <SchemaDevelopment {...props} />,
    },
    {
      path: "/services/api-development",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <ApiDevelopment {...props} />,
    },
    {
      path: "/services/ui-and-ux",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <UiUxService {...props} />,
    },
    {
      path: "/services/design-consultation",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <DesignConsultation {...props} />,
    },
    {
      path: "/services/ui-ux-design",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <UiUxDesign {...props} />,
    },
    {
      path: "/services/user-research-and-feedback",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <UserResearchAndFeedback {...props} />,
    },
    {
      path: "/services/wire-framing-and-mockups",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <WireFramingAndMockups {...props} />,
    },
    {
      path: "/services/mobile-development",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <MobileDevelopment {...props} />,
    },
    {
      path: "/services/android-development",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <AndroidDevelopment {...props} />,
    },
    {
      path: "/services/ios-development",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <IosDevelopment {...props} />,
    },
    {
      path: "/services/cross-platform-development",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <CrossPlatformDevelopment {...props} />,
    },
    {
      path: "/services/react-native-development",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <ReactNativeDevelopment {...props} />,
    },
    {
      path: "/technologies",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <TechnologiesPage {...props} />,
    },
    {
      path: "/work-methodology",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <OurMethodology {...props} />,
    },
    {
      path: "/terms-and-conditions",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <TermsConditions {...props} />,
    },
    {
      path: "/privacy-policy",
      exact: true,
      navbar: (props) => <NewNavbar {...props} />,
      footer: () => <Footer />,
      main: (props) => <PrivacyPolicy {...props} />,
    },
    {
      navbar: (props) => <></>,
      footer: () => <></>,
      main: (props) => <NotFoundPage {...props} />,
    },
  ];

  return (
    <Suspense
      fallback={
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <PacmanLoader color="#19275F" size={40} />
        </div>
      }
    >
      <Router>
        <Switch>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              children={(props) => <route.navbar {...props} />}
            />
          ))}
        </Switch>
        <Switch>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              children={(props) => (
                <route.main subRoutes={route.routes} {...props} />
              )}
            />
          ))}
        </Switch>
        <Switch>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              children={(props) => <route.footer {...props} />}
            />
          ))}
        </Switch>
      </Router>
    </Suspense>
  );
};

export default routesView;
